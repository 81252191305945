import { Swiper, SwiperSlide } from "swiper/react";
import slide1 from '../../assets/img/home/hero11.jpg';
import slide4 from '../../assets/img/home/hero14.jpg';
import slide5 from '../../assets/img/home/hero15.jpg';
import slide2 from '../../assets/img/home/hero16.jpg';
import slide3 from '../../assets/img/home/hero17.jpg';
import { EffectFade } from 'swiper';
import { useTranslation } from 'react-i18next'

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import { Navigation } from "swiper";
import { Link } from "react-router-dom";
import 'swiper/css/effect-fade';

const Hero = () => {
    const { t } = useTranslation()
    return (
        <section className="hero-wrapper hero-2">
            <Swiper navigation={true} modules={[Navigation, EffectFade]} effect={"fade"} loop>
                <SwiperSlide className="hero-slider-2">
                    <div className="single-slide bg-cover" style={{backgroundImage: `url(${slide1})`}}>
                        <div className="container">
                            <div className="row">
                                <div className="col-12 pe-lg-5 col-xxl-7 col-lg-9">
                                    <div className="hero-contents pe-lg-3">
                                        <h1 className="wow fadeInLeft animated" data-wow-duration="1.3s">{t("Pages.Home.Hero1.Title")}</h1>
                                        <p className="pe-lg-5 wow fadeInLeft animated" data-wow-duration="1.3s" data-wow-delay=".4s">{t("Pages.Home.Hero1.Description")}</p>
                                        <Link to="/kontakt" className="theme-btn me-sm-4 wow fadeInLeft" data-wow-duration="1.2s" data-wow-delay=".8s">{t("Pages.Home.ContactButton")}</Link>
                                        <Link to="/" onClick={() => window.location.replace("/#company-activity")} className="theme-btn wow fadeInLeft" data-wow-duration="1.2s" data-wow-delay=".6s">{t("Pages.Home.Hero1.Button")}</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </SwiperSlide>
                <SwiperSlide className="hero-slider-2">
                    <div className="single-slide bg-cover" style={{backgroundImage: `url(${slide2})`}}>
                        <div className="container">
                            <div className="row">
                                <div className="col-12 pe-lg-5 col-xxl-7 col-lg-9">
                                    <div className="hero-contents pe-lg-3">
                                        <h1 className="wow fadeInLeft animated" data-wow-duration="1.3s">{t("Pages.Home.Hero2.Title")}</h1>
                                        <p className="pe-lg-5 wow fadeInLeft animated" data-wow-duration="1.3s" data-wow-delay=".4s">{t("Pages.Home.Hero2.Description")}</p>
                                        <Link to="/kontakt" className="theme-btn me-sm-4 wow fadeInLeft" data-wow-duration="1.2s" data-wow-delay=".8s">{t("Pages.Home.ContactButton")}</Link>
                                        <a href="https://sklep.komado.pl/" target="_blank" className="theme-btn wow fadeInLeft" data-wow-duration="1.2s" data-wow-delay=".6s">{t("Pages.Home.Hero2.Button")}</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </SwiperSlide>
                <SwiperSlide className="hero-slider-2">
                    <div className="single-slide bg-cover" style={{backgroundImage: `url(${slide3})`}}>
                        <div className="container">
                            <div className="row">
                                <div className="col-12 pe-lg-5 col-xxl-7 col-lg-9">
                                    <div className="hero-contents pe-lg-3">
                                        <h1 className="wow fadeInLeft animated" data-wow-duration="1.3s">{t("Pages.Home.Hero1.Title")}</h1>
                                        <p className="pe-lg-5 wow fadeInLeft animated" data-wow-duration="1.3s" data-wow-delay=".4s">{t("Pages.Home.Hero1.Description")}</p>
                                        <Link to="/kontakt" className="theme-btn me-sm-4 wow fadeInLeft" data-wow-duration="1.2s" data-wow-delay=".8s">{t("Pages.Home.ContactButton")}</Link>
                                        <Link to="/" onClick={() => window.location.replace("/#company-activity")} className="theme-btn wow fadeInLeft" data-wow-duration="1.2s" data-wow-delay=".6s">{t("Pages.Home.Hero1.Button")}</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </SwiperSlide>
                <SwiperSlide className="hero-slider-2">
                    <div className="single-slide bg-cover" style={{backgroundImage: `url(${slide4})`}}>
                        <div className="container">
                            <div className="row">
                                <div className="col-12 pe-lg-5 col-xxl-7 col-lg-9">
                                    <div className="hero-contents pe-lg-3">
                                        <h1 className="wow fadeInLeft animated" data-wow-duration="1.3s">{t("Pages.Home.Hero1.Title")}</h1>
                                        <p className="pe-lg-5 wow fadeInLeft animated" data-wow-duration="1.3s" data-wow-delay=".4s">{t("Pages.Home.Hero1.Description")}</p>
                                        <Link to="/kontakt" className="theme-btn me-sm-4 wow fadeInLeft" data-wow-duration="1.2s" data-wow-delay=".8s">{t("Pages.Home.ContactButton")}</Link>
                                        <Link to="/" onClick={() => window.location.replace("/#company-activity")} className="theme-btn wow fadeInLeft" data-wow-duration="1.2s" data-wow-delay=".6s">{t("Pages.Home.Hero1.Button")}</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </SwiperSlide>
                <SwiperSlide className="hero-slider-2">
                    <div className="single-slide bg-cover" style={{backgroundImage: `url(${slide5})`}}>
                        <div className="container">
                            <div className="row">
                                <div className="col-12 pe-lg-5 col-xxl-7 col-lg-9">
                                    <div className="hero-contents pe-lg-3">
                                        <h1 className="wow fadeInLeft animated" data-wow-duration="1.3s">{t("Pages.Home.Hero1.Title")}</h1>
                                        <p className="pe-lg-5 wow fadeInLeft animated" data-wow-duration="1.3s" data-wow-delay=".4s">{t("Pages.Home.Hero1.Description")}</p>
                                        <Link to="/kontakt" className="theme-btn me-sm-4 wow fadeInLeft" data-wow-duration="1.2s" data-wow-delay=".8s">{t("Pages.Home.ContactButton")}</Link>
                                        <Link to="/" onClick={() => window.location.replace("/#company-activity")} className="theme-btn wow fadeInLeft" data-wow-duration="1.2s" data-wow-delay=".6s">{t("Pages.Home.Hero1.Button")}</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </SwiperSlide>
            </Swiper>
        </section>
    )
}

export default Hero;