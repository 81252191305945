import React from 'react'
import SelectLanguage from './SelectLanguage'
import { useTranslation } from 'react-i18next'

const HeaderTop = () => {
    const { t } = useTranslation()
    return (
        <div className="header-top-bar text-white d-none d-sm-block">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-lg-9 col-sm-9">
                        <ul className="top-left-content">
                        <li><i className="flaticon-paper-plane"></i><a style={{fontWeight: 400, textTransform: 'none'}} href="mailto:kontakt@komado.com.pl"> kontakt@komado.com.pl</a></li>
                            <li><i className="flaticon-map"></i>{t('Adress.Long')}</li>
                        </ul>
                    </div>
                    <div className="language-col">
                        <SelectLanguage isMobile={false}/>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default HeaderTop