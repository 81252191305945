import aboutUsHero from '../../assets/img/home/About_us_hero.jpg'
import aboutUsSmall from '../../assets/img/home/About_us_small.jpg'
import OutsorcingImage from '../../assets/img/home/OutsourcingImage.jpg'
import { useState } from 'react';
import 'react-modal-video/scss/modal-video.scss';
import ModalVideo from 'react-modal-video';
import { useTranslation } from 'react-i18next'


const About = ({pt}) => {
    const { t } = useTranslation()
    const [isOpen, setOpen] = useState(false);

    return (
        <>
        <ModalVideo
            channel="youtube"
            autoplay
            isOpen={isOpen}
            videoId="EZ9DrY43wtw"
            onClose={() => setOpen(false)}
        />
        <section className={pt ? 'about-section section-padding' : 'about-section section-padding pt-0'} id="about">
            <div className="container">
                <div className="row">
                    <div className="col-xl-6 col-lg-5 col-12">
                        <div className="img-block-pop-video">
                            <img src={aboutUsHero} alt="about-us"/>
                        </div>
                    </div>
                    <div className="col-xl-6 col-lg-7 col-md-10 col-12">
                        <div className="block-contents">
                            <div className="section-title">
                                <span>{t("Pages.Home.AboutUs.SectionTitle")}</span>
                                <h2>{t("Pages.Home.AboutUs.Title")}</h2>
                            </div>
                            <p>{t("Pages.Home.AboutUs.Description")}</p>
                        </div>
                        <div className="tab-content-block">
                            <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
                                <li className="nav-item" role="presentation">
                                    <button className="nav-link active" id="pills-technology-tab" data-bs-toggle="pill" data-bs-target="#pills-technology" type="button" role="tab" aria-controls="pills-technology" aria-selected="true">{t("Pages.Home.AboutUs.List.1.Title")}</button>
                                </li>
                                <li className="nav-item" role="presentation">
                                    <button className="nav-link" id="pills-materials-tab" data-bs-toggle="pill" data-bs-target="#pills-materials" type="button" role="tab" aria-controls="pills-materials" aria-selected="false">{t("Pages.Home.AboutUs.List.2.Title")}</button>
                                </li>
                            </ul>
                            <div className="tab-content" id="pills-tabContent">
                                <div className="tab-pane fade show active" id="pills-technology" role="tabpanel" aria-labelledby="pills-technology-tab">
                                    <div className="tab-inner-contents">
                                        <div className="img-box">
                                            <img src={aboutUsSmall} alt=""/>
                                        </div>
                                        <div className="checked-features-list">
                                            <ul>
                                                <li>{t("Pages.Home.AboutUs.List.1.1")}</li>
                                                <li>{t("Pages.Home.AboutUs.List.1.2")}</li>
                                                <li>{t("Pages.Home.AboutUs.List.1.3")}</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="tab-pane fade" id="pills-materials" role="tabpanel" aria-labelledby="pills-materials-tab">
                                    <div className="tab-inner-contents">
                                        <div className="img-box">
                                            <img src={OutsorcingImage} alt=""/>
                                        </div>
                                        <div className="checked-features-list">
                                            <ul>
                                                <li>{t("Pages.Home.AboutUs.List.2.1")}</li>
                                                <li>{t("Pages.Home.AboutUs.List.2.2")}</li>
                                                <li>{t("Pages.Home.AboutUs.List.2.3")}</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="tab-pane fade" id="pills-approved" role="tabpanel" aria-labelledby="pills-approved-tab">
                                    <div className="tab-inner-contents">
                                        <div className="img-box">
                                            <img src="assets/img/home1/tab-img.jpg" alt=""/>
                                        </div>
                                    </div>
                                </div>
                            </div>                          
                        </div>
                    </div>
                </div>
            </div>
        </section>
        </>
    )
}

export default About;