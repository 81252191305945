import React, { useState } from 'react'
import HeaderTop from './HeaderTop'
import mainLogo from '../../assets/img/logo_k.png';
import { Link } from 'react-router-dom';
import MobileMenu from './MobileMenu';
import { useTranslation } from 'react-i18next'

const Header = () => {
    const { t } = useTranslation()
    const [mobileMenu, setMobileMenu] = useState(true);

    const handleMobileMenu = () => {
        setMobileMenu(!mobileMenu);
      }

  return (
    <header className="header-wrap header-2">
       <HeaderTop/>
        <div className="main-header-wrapper">
            <div className="container-fluid align-items-center justify-content-between d-flex">
                <div className="logo">
                    <Link to="/"><img src={mainLogo} alt="logo"/></Link>
                </div>
                <div className="main-menu d-none d-lg-block">
                    <ul>
                        <li><Link to="/">{t("Header.Home")}</Link></li>
                        <li><Link to="/" onClick={() => window.location.replace("/#about") }>{t("Header.AboutUs")}</Link></li>
                        <li><Link to="/" onClick={() => window.location.replace("/#company-activity")}>{t("Header.Activity")}</Link></li>
                        <li><a href="https://sklep.komado.pl/" target="_blank">{t("Header.Shop")}</a></li>
                        <li><Link to="/kontakt">{t("Header.Contact")}</Link></li>
                    </ul>
                </div>
                <div className="d-inline-block d-lg-none">
                    <div className="mobile-nav-wrap">                    
                        <div id="hamburger" onClick={handleMobileMenu}>
                            <i className="fal fa-bars"></i>
                        </div>
                       <MobileMenu mobileMenu={mobileMenu} handleMobileMenu={handleMobileMenu} />
                    </div>
                    <div className="overlay"></div>
                </div>
                <div className="right-elements d-none d-xl-flex d-flex align-items-center">
                    <div className="call-action">
                        <span> {t("Header.CallLabel")}</span>
                        <p>+48 228116074</p>
                    </div>
                </div>
            </div>
        </div>
    </header>
  )
}

export default Header