import React from 'react'
import { Link } from 'react-router-dom'
import SelectLanguage from './SelectLanguage'
import { useTranslation } from 'react-i18next'

const MobileMenu = ({mobileMenu, handleMobileMenu}) => {
    const { t } = useTranslation()
  return (
    <div className={mobileMenu ? 'mobile-nav' : 'mobile-nav show'}>
        <button onClick={handleMobileMenu} type="button" className="close-nav">
            <i className="fal fa-times-circle"></i>
        </button>
        <nav className="sidebar-nav">
            <ul className="metismenu" id="mobile-menu">
                <li><Link to="/">{t("Header.Home")}</Link></li>
                <li><Link to="/" onClick={() => window.location.replace("/#about") }>{t("Header.AboutUs")}</Link></li>
                <li><Link to="/" onClick={() => window.location.replace("/#company-activity")}>{t("Header.Activity")}</Link></li>
                <li><Link to="/kontakt">{t("Header.Contact")}</Link></li>
            </ul>
        </nav>

        <div className="action-bar">
            <a href="mailto:kontakt@komado.com.pl"><i className="fal fa-envelope"></i>kontakt@komado.com.pl</a>
            <a href="tel:+48 228116074"><i className="fal fa-phone"></i>+48 228116074</a>
            <Link to='/kontakt' className="d-btn theme-btn">{t("Header.Contact")}</Link>
            <SelectLanguage isMobile={true}/>
        </div>
    </div>    
     
  )
}

export default MobileMenu