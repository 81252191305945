import React from 'react'
import { Link } from 'react-router-dom'
import servicesData from './servicesData'
import { useTranslation } from 'react-i18next'

const Services = () => {
    const { t } = useTranslation()
    var key = 1
    return (
        <section className="our-service-wrapper section-padding bg-gray" id="company-activity">
            <div className="container">
                <div className="row">
                    <div className="col-xl-12">
                        <div className="block-contents text-center">
                            <div className="section-title">
                                <h5>{t("Pages.Home.Services.Title1")}</h5>
                                <span>{t("Pages.Home.Services.Title2")}</span>
                                <h2>{t("Pages.Home.Services.Title3")}</h2>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row">
                    {
                        servicesData.map((data) => (
                            <div className="col-xl-4 col-md-6 col-12" key={key++}>
                                <div className="single-service-card">
                                    <div className="service-cover-img bg-cover" style={{backgroundImage:`url(${data.img})`}}></div>
                                    <div className="content">
                                        <div className="icon-title">
                                            <div className="icon">
                                                <i className={`own-flaticon-${data.icon}`}></i>
                                            </div>
                                            <div className="service-title">
                                                <h4>{         
                                                    data.link ? <Link to={ "/service/" + data.link }>{t(`ServicesData.${key}.Title`)}</Link> : <>{t(`ServicesData.${key}.Title`)}</>
                                                    }
                                                </h4>
                                            </div>
                                        </div>
                                        <p>{t(`ServicesData.${key}.Description`)}</p>
                                        {
                                            data.link ? <Link to={ "/service/" + data.link } className="read-more">{t("Pages.Home.Services.LearnMore")}<i className="fal fa-arrow-right"></i></Link> : null
                                        }
                                        
                                    </div>
                                </div>
                                
                            </div>
                        ))
                    }
                    
                </div>
            </div>
        </section>
    )
}

export default Services