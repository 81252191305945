import Services from "../Components/Services/Services";
import Footer from "../Components/Footer/Footer";
import Header from "../Components/Header/Header";
import Hero from "../Components/Hero/Hero";
import About from "../Components/About/About";

const Home = () => {
    return (
        <>
            <Header/>
            <Hero/>
            <About pt/>
            <Services/>
            <Footer/>
        </>
    )
}

export default Home;