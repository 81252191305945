import React from 'react'
import Footer from '../Components/Footer/Footer';
import Header from '../Components/Header/Header';
import ProductKruszarka from '../Components/ProductDetails/ProductKruszarka';

const KruszarkaDetails = () => {
  return (
    <>
        <Header/>
        <ProductKruszarka/>
        <Footer/>
    </>
  )
}

export default KruszarkaDetails