import React from 'react'
import Footer from '../Components/Footer/Footer'
import Header from '../Components/Header/Header'
import ContactPageContents from "../Components/Contact/ContactPageContents";

const ContactPage = () => {
  return (
    <>
        <Header/>
        <ContactPageContents/>
        <Footer/>
    </>
  )
}

export default ContactPage;
