import React from 'react'
import { Link } from 'react-router-dom'
import FooterOneCopyrights from './FooterCopyrights'

import { useTranslation } from 'react-i18next'

const Footer = () => {
    const { t } = useTranslation()
    return (
        <footer className="footer-2 footer-wrap">
            <div className="footer-widgets-wrapper text-white">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-3 pe-xl-0 col-sm-6 col-12">
                            <div className="single-footer-wid site_info_widget">
                                <div className="wid-title">
                                    <h3>{t('Footer.AdressLabel')}</h3>
                                </div>
                                <div className="contact-us">
                                    <div className="single-contact-info">
                                        <div className="icon">
                                            <i className="fal fa-phone"></i>
                                        </div>
                                        <div className="contact-info">
                                            <p>+48 22 811 60 74</p>
                                        </div>
                                    </div>
                                    <div className="single-contact-info">
                                        <div className="icon">
                                            <i className="fal fa-envelope"></i>
                                        </div>
                                        <div className="contact-info">
                                            <p>kontakt@komado.com.pl</p>
                                        </div>
                                    </div>
                                    <div className="single-contact-info">
                                        <div className="icon">
                                            <i className="fal fa-map-marker-alt"></i>
                                        </div>
                                        <div className="contact-info">
                                            <p>{t("Adress.Street")}<br/>{t("Adress.ZipCode")+" " + t("Adress.City")}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-sm-6 offset-xl-1 col-xl-3 ps-xl-5 col-12">
                            <div className="single-footer-wid">
                                <div className="wid-title">
                                    <h3>{t("Footer.LinksLabel")}</h3>
                                </div>
                                <ul>
                                    <li><Link to="/" onClick={() => window.location.replace("/#about") }>{t("Header.AboutUs")}</Link></li>
                                    <li><Link to="/" onClick={() => window.location.replace("/#company-activity")}>{t("Header.Activity")}</Link></li>
                                    <li><Link to="/kontakt">{t("Header.Contact")}</Link></li>
                                </ul>
                            </div>
                        </div> 

                        <div className="col-sm-6 col-xl-4 offset-xl-1 col-12">                        
                            <div className="single-footer-wid newsletter_widget">
                                <div className="wid-title">
                                    <h3>{t("Footer.LocalisationLabel")}</h3>
                                </div>
                                <div className="map-location">
                                    <iframe 
                                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2445.571762967533!2d20.877916645324643!3d52.196656372322316!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x471ecc1b8528e29b%3A0xbba7e528fec57fea!2sKomado.%20Zak%C5%82ad%20konstruowania%20maszyn%20i%20urz%C4%85dze%C5%84!5e0!3m2!1spl!2spl!4v1694428483988!5m2!1spl!2spl" 
                                        width="370" 
                                        height="200" 
                                        style={{border:0}} 
                                        allowfullscreen="" 
                                        loading="lazy" 
                                        referrerpolicy="no-referrer-when-downgrade">
                                    </iframe>
                                </div>
                            </div>
                        </div> 
                    </div>
                </div>
            </div>
            <FooterOneCopyrights/>
        </footer> 
    )
}

export default Footer