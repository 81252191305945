import React from 'react'
import { Link } from 'react-router-dom'
import KruszarkaPhoto from '../../assets/img/products/KruszarkaPhoto.jpg'
import Gruz from '../../assets/img/products/Gruz.jpg'
import Szklo from '../../assets/img/products/Szkło.jpg'
import { useTranslation } from 'react-i18next'

const ProductKruszarka = () => {
    const { t } = useTranslation()
    return (
        <section className="blog-wrapper news-wrapper section-padding">
            <div className="container">
                <div className="row">
                    <div className="col-12 col-lg-8">
                        <div className="blog-post-details border-wrap">
                            <div className="single-blog-post post-details">                            
                                <div className="post-content">
                                    <div className="post-cat">
                                        <Link to="/" onClick={() => window.location.replace("/#company-activity")}>{t("Pages.GlassCrusher.Title")}</Link>
                                    </div>
                                    <h2>{t("Pages.GlassCrusher.ProductName")}</h2>
                                    <img className="alignleft" src={KruszarkaPhoto} alt={t("Pages.GlassCrusher.ImageAlt")}/>

                                    <p>{t("Pages.GlassCrusher.ProductDesc1")}</p>
                                    <p>{t("Pages.GlassCrusher.UsageList.Title")} 
                                        <li className="kruszarka-usage-list">{t("Pages.GlassCrusher.UsageList.1")}</li>
                                        <li className="kruszarka-usage-list">{t("Pages.GlassCrusher.UsageList.2")}</li>
                                        <li className="kruszarka-usage-list">{t("Pages.GlassCrusher.UsageList.3")}</li>
                                        <li className="kruszarka-usage-list">{t("Pages.GlassCrusher.UsageList.4")}</li>
                                        <li className="kruszarka-usage-list">{t("Pages.GlassCrusher.UsageList.5")}</li>
                                        <li className="kruszarka-usage-list">{t("Pages.GlassCrusher.UsageList.6")}</li>
                                        <li className="kruszarka-usage-list">{t("Pages.GlassCrusher.UsageList.7")}</li>
                                    </p>
                                    <br/>
                                    <br/>

                                    <div className="kruszarka-image-container">
                                        <div className="item">
                                            <img src={Szklo} alt={t("Pages.GlassCrusher.ImageAlt")}/>
                                            <p>{t("Pages.GlassCrusher.Images.Glass")}</p>
                                        </div>
                                        <div className="item">
                                            <img src={Gruz} alt={t("Pages.GlassCrusher.ImageAlt")}/>
                                            <p>{t("Pages.GlassCrusher.Images.Rubble")}</p>
                                        </div>
                                    </div>

                                    <h3>{t("Pages.GlassCrusher.Parameters.Title")}</h3>
                                    <ul>
                                        <li>{t("Pages.GlassCrusher.Parameters.1")}</li>
                                        <li>{t("Pages.GlassCrusher.Parameters.2")}</li>
                                        <li>{t("Pages.GlassCrusher.Parameters.3")}</li>
                                    </ul>
                                    <p>{t("Pages.GlassCrusher.Parameters.Desc")}</p>
                                    <br/>

                                    <h3>{t("Pages.GlassCrusher.TechnicalData.Title")}</h3>
                                    <p>
                                        <li className="kruszarka-usage-list">{t("Pages.GlassCrusher.TechnicalData.1")}</li>
                                        <li className="kruszarka-usage-list">{t("Pages.GlassCrusher.TechnicalData.2")}</li>
                                        <li className="kruszarka-usage-list">{t("Pages.GlassCrusher.TechnicalData.3")}</li>
                                        <li className="kruszarka-usage-list">{t("Pages.GlassCrusher.TechnicalData.4")}</li>
                                        <li className="kruszarka-usage-list">{t("Pages.GlassCrusher.TechnicalData.5")}</li>
                                    </p>
                                    <br/>

                                    <h3>{t("Pages.GlassCrusher.Loading.Title")}</h3>
                                    <p>{t("Pages.GlassCrusher.Loading.1")}</p>
                                    <p>{t("Pages.GlassCrusher.Loading.2")}</p>
                                    <br/>

                                    <h3>{t("Pages.GlassCrusher.Equipment.Title")}</h3>
                                    <p>{t("Pages.GlassCrusher.Equipment.1")}</p>
                                    <p>{t("Pages.GlassCrusher.Equipment.2")}</p>
                                    <br/>

                                    <h3>{t("Pages.GlassCrusher.AdditionalEquipment.Title")}</h3>
                                    <p>
                                        <li className="kruszarka-usage-list">{t("Pages.GlassCrusher.AdditionalEquipment.1")}</li>
                                        <li className="kruszarka-usage-list">{t("Pages.GlassCrusher.AdditionalEquipment.2")}</li>
                                        <li className="kruszarka-usage-list">{t("Pages.GlassCrusher.AdditionalEquipment.3")}</li>
                                        <li className="kruszarka-usage-list">{t("Pages.GlassCrusher.AdditionalEquipment.4")}</li>
                                        <li className="kruszarka-usage-list">{t("Pages.GlassCrusher.AdditionalEquipment.5")}</li>
                                        <li className="kruszarka-usage-list">{t("Pages.GlassCrusher.AdditionalEquipment.6")}</li>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default ProductKruszarka