import React from 'react'
import PolishLanguageImage from '../../assets/img/flags/poland.png'
import EnglishLanguageImage from '../../assets/img/flags/united-kingdom.png'
import { useTranslation } from 'react-i18next'

const SelectLanguage = ({ isMobile }) => {
    const { i18n } = useTranslation()

    const updateLanguage = (language) => {
        i18n.changeLanguage(language)
    }

    const SelectDefault = () => {
        return (
            <ul className="top-right-content">
                <li>
                    <button onClick={() => updateLanguage("pl")} className="flag-button"><img src={PolishLanguageImage} loading='lazy'/></button>
                </li>
                <li>
                    <button onClick={() => updateLanguage("en")} className="flag-button"><img src={EnglishLanguageImage} loading='lazy'/></button>
                </li>
            </ul>
        )
    }
    
    const SelectMobile = () => {
        return ( 
            <div className='mobile-language-list'>
                <button onClick={() => updateLanguage("pl")}><img src={PolishLanguageImage} loading='lazy'/></button>
                <button onClick={() => updateLanguage("en")}><img src={EnglishLanguageImage} loading='lazy'/></button>
            </div>
        )
    }

    return (
        <>
            { isMobile ? <SelectMobile/> : <SelectDefault/> }
        </>
    )
}

export default SelectLanguage