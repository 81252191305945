import { useRef } from "react";
import emailjs from "@emailjs/browser"
import Swal from 'sweetalert2'
import { useTranslation } from 'react-i18next'


const ContactPageContents = () => {
    const { t } = useTranslation()
    const form = useRef()

    const sendEmail = (e) => {
        e.preventDefault()
        emailjs.sendForm( process.env.REACT_APP_EMAIL_SERVICE_ID, process.env.REACT_APP_EMAIL_TEMPLATE_ID, form.current, process.env.REACT_APP_EMAIL_PUBLIC_KEY )
        .then(( result ) => {
            Swal.fire({
                icon: 'success',
                title: t("Pages.Contact.Form.SendSuccess")
            })     
        }, (error) => {
            Swal.fire({
                icon: 'error',
                title: t("Pages.Contact.Form.SendError"),
                text: error.text,
              })
        })
        e.target.reset()
    }
    return (
        <div className="contact-us-wrapper section-padding">
            <div className="container">
                <div className="row eq-height">
                <div className="col-lg-8 col-12">
                    <div className="contact-form"> 
                    <h2>{t("Pages.Contact.ContactUsLabel")}</h2>                                                       
                    
                    <form ref={form} onSubmit={sendEmail} className="row" id="contact-form">
                    <div className="col-md-6 col-12">
                        <div className="single-personal-info">
                        <input type="text" name="name" placeholder={t("Pages.Contact.Form.Placeholders.NameOrCompany")} />                                         
                        </div>
                    </div>                            
                    <div className="col-md-6 col-12">
                        <div className="single-personal-info">
                        <input type="email" name="email" placeholder="Email" />                                         
                        </div>
                    </div>
                    <div className="col-md-6 col-12">
                        <div className="single-personal-info">
                        <input type="text" name="phone" placeholder={t("Pages.Contact.Form.Placeholders.PhoneNumber")} />                                         
                        </div>
                    </div>                                      
                    <div className="col-md-6 col-12">
                        <div className="single-personal-info">
                        <input type="text" name="subject" placeholder={t("Pages.Contact.Form.Placeholders.Topic")} />                                         
                        </div>
                    </div>                                      
                    <div className="col-md-12 col-12">
                        <div className="single-personal-info">
                        <textarea name="message" placeholder={t("Pages.Contact.Form.Placeholders.Message")} defaultValue={""} />                                        
                        </div>
                    </div>                                      
                    <div className="col-md-12 col-12">
                        <input className="submit-btn" type="submit" value={t("Pages.Contact.Form.Placeholders.Submit")} />
                    </div>                                      
                    </form>
                    <span className="form-message" />
                    </div>
                </div>
                <div className="col-lg-4 col-12">
                    <div className="contact-us-sidebar mt-5 mt-lg-0">
                        <div className="contact-info">
                            <h2>{t("Pages.Contact.ContactUsLabel")}</h2>
                            <div className="single-info">
                                <div className="icon">
                                    <i className="flaticon-email" />
                                </div>
                                <div className="text">
                                    <span>{t("Pages.Contact.TextToLabel")}</span>
                                    <h6>kontakt@komado.com.pl</h6>
                                </div>
                            </div>
                            <div className="single-info">
                                <div className="icon">
                                    <i className="flaticon-phone-call-1" />
                                </div>
                                <div className="text">
                                    <span>{t("Pages.Contact.CallLabel")}</span>
                                    <h5>+48 22 811 60 74</h5>
                                </div>
                            </div>
                            <div className="single-info">
                                <div className="icon">
                                    <i className="flaticon-pin" />
                                </div>
                                <div className="text">
                                    <span>{t("Pages.Contact.AdressLabel")}</span>
                                    <h5>{t("Adress.Street")}</h5>
                                    <h5>{t("Adress.ZipCode")+" "+t("Adress.City").replace(", " + t("Adress.Country"), "")}</h5>
                                    <h5>{t("Adress.Country")}</h5>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                </div>
            </div>
        </div>

    )
}

export default ContactPageContents;