import {v4 as uuidv4} from 'uuid';
import img1 from '../../assets/img/home/linie2-740x460.jpg';
import img2 from '../../assets/img/home/stanowiska-740x460.jpg';
import img3 from '../../assets/img/home/robocze-740x460.jpg'
import img4 from '../../assets/img/home/spawalnicze-740x460.jpg'
import img5 from '../../assets/img/home/rolnicze-740x460.jpg'
import img6 from '../../assets/img/home/linie-740x460.jpg'
import img7 from '../../assets/img/home/pneumatyka-740x460.jpg'
import img8 from '../../assets/img/home/hydraulika-740x460.jpg'
import img9 from '../../assets/img/home/kru-740x460.jpg'

const servicesTwoData = [
    {
        id: uuidv4(),
        img: img9,
        icon: 'crusher-2',
        title: 'ServicesData.1.Title',
        desc: 'ServicesData.1.Description',
        link: "kruszarka",
    },
    {
        id: uuidv4(),
        img: img1,
        icon: 'production',
        title: 'Dedykowane linie produkcyjne',
        desc: 'Projektujemy linie montażowe dla różnych gałęzi przemysłu',
    },

    {
        id: uuidv4(),
        img: img2,
        icon: 'assemble',
        title: 'Stanowiska montażowe',
        desc: 'Projektujemy i dostarczamy stanowiska montażowe ręczne, półautomatyczne i automatyczne, stanowiska badawcze i testowe',
    },

    {
        id: uuidv4(),
        img: img3,
        icon: 'drilling-rig',
        title: 'Osprzęty maszyn ciężkich',
        desc: 'Projektujemy osprzęty specjalistyczne do maszyn ciężkich, wiertnice, palownice i inne',
    },

    {
        id: uuidv4(),
        img: img4,
        icon: 'industrial',
        title: 'Stanowiska spawalnicze',
        desc: 'Projektujemy i dostarczamy urządzenia automatyzujące procesy spawalnicze',
    },

    {
        id: uuidv4(),
        img: img5,
        icon: 'drone',
        title: 'Specjalistyczne maszyny rolnicze',
        desc: 'Projektujemy wyspecjalizowane maszyny rolnicze',
    },

    {
        id: uuidv4(),
        img: img6,
        icon: 'transport',
        title: 'Linie do przerobu surowców',
        desc: 'Projektujemy przenośniki, rozdrabniacze, kruszarki, konstrukcje wsporcze, pomosty',
    },
    
    {
        id: uuidv4(),
        img: img7,
        icon: 'air-compressor',
        title: 'Napędy pneumatyczne',
        desc: 'Projektujemy i dostarczamy pneumatyczne układy napędowe',
    },

    {
        id: uuidv4(),
        img: img8,
        icon: 'hydraulic-3',
        title: 'Napędy hydrauliczne',
        desc: 'Projektujemy hydrauliczne układy napędowe. Dostarczamy dedykowane zasilacze hydrauliczne',
    },
]

export default servicesTwoData;