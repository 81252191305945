import { Routes, Route } from 'react-router-dom';  
import ScrollTopBtn from './Components/ScrollTopBtn';
import Home from './pages/Home';
import ContactPage from './pages/ContactPage';
import KruszarkaDetails from './pages/KruszarkaDetails';
import ScrollToTop from './Components/ScrollToTop';


function App() {
  return (
    <>
      <ScrollTopBtn/>
      <ScrollToTop/>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/kontakt" element={<ContactPage />} />
        <Route path="/service/kruszarka" element={<KruszarkaDetails />} />
      </Routes>
    </>
  )
}

export default App;
